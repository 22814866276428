import { render, staticRenderFns } from "./forms-table.vue?vue&type=template&id=257967da&scoped=true&"
import script from "./forms-table.vue?vue&type=script&lang=js&"
export * from "./forms-table.vue?vue&type=script&lang=js&"
import style0 from "./forms-table.vue?vue&type=style&index=0&id=257967da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "257967da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VIcon,VProgressLinear,VSimpleTable,VTooltip})
