<template>
  <v-dialog v-model="confirmationDialog" persistent max-width="600px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small text v-on="{ ...tooltip, ...dialog }" v-bind="attrs">
            <slot name="button">
              <v-icon>mdi-delete-forever</v-icon>
            </slot>
          </v-btn>
        </template>
        <span><slot name="tooltip"></slot></span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5"><slot name="header"></slot></span>
      </v-card-title>
      <v-card-text><slot></slot></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel">
          <slot name="close-button">{{ $t('cancel') }}</slot>
        </v-btn>
        <v-btn color="blue darken-1" text @click="confirm">
          <slot name="confirm-button">{{ $t('save') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    confirmationDialog: false
  }),

  methods: {
    cancel() {
      this.confirmationDialog = false;
    },

    confirm() {
      this.$emit('confirmed');
      this.confirmationDialog = false;
    }
  }
};
</script>
