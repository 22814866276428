<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small text v-on="{ ...tooltip, ...dialog }" v-bind="attrs">
            <slot name="button">
              <v-icon>mdi-share-variant</v-icon>
            </slot>
          </v-btn>
        </template>
        <span>{{ $t('forms.share') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('forms.share') }}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="!processing && !formSent">
          <p>{{ $t('forms.shareInfo') }}</p>

          <v-form v-model="isValid" ref="form">
            <v-text-field
              v-model="email"
              label="E-Mail Adresse"
              hide-details="auto"
              :rules="[(v) => !!v || $t('validation.fieldIsRequired')]"
              required
            />
          </v-form>
        </div>

        <div v-if="processing" class="text-center mt-8">
          <v-progress-circular :size="50" indeterminate></v-progress-circular>
        </div>

        <div v-if="formSent">
          <p v-html="$t('forms.shareConfirmationInfo', { url: resultUrl })">
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <template v-if="!formSent">
          <v-btn color="blue darken-1" text @click="cancel">
            <slot name="close-button">{{ $t('cancel') }}</slot>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirm"
            :loading="processing"
          >
            <slot name="confirm-button">{{ $t('save') }}</slot>
          </v-btn>
        </template>
        <template v-else>
          <v-btn color="blue darken-1" text @click="copyUrl">
            <v-icon>{{ copyLinkIcon }}</v-icon>
            {{ $t('forms.copyShareLink')}}
          </v-btn>
          <v-btn color="blue darken-1" text @click="cancel">
            <slot name="close-button">{{ $t('close') }}</slot>
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    formId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    isValid: false,
    showDialog: false,
    email: '',
    formSent: false,
    processing: false,
    resultUrl: '',
    copyLinkIcon: 'mdi-content-copy',
    defaultCopyLinkIcon: 'mdi-content-copy'
  }),

  methods: {
    ...mapActions('feedbacks', {
      createAnonymousFeedback: 'createAnonymousFeedback'
    }),

    cancel() {
      this.processing = false;
      this.formSent = false;

      this.resultUrl = '';
      this.email = '';

      this.copyLinkIcon = this.defaultCopyLinkIcon;

      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.showDialog = false;
    },

    async confirm() {
      await this.$refs.form.validate();

      if (!this.isValid) {
        return;
      }

      this.processing = true;

      const result = await this.createAnonymousFeedback({
        email: this.email,
        formId: this.formId
      });

      const url = this.$router.resolve({
        name: 'PrintFeedback',
        params: {
          id: result.result.id,
          accessToken: result.result.accessToken
        }
      });

      this.resultUrl = `${window.location.origin}/${url.href}`;

      this.processing = false;
      this.formSent = true;
    },

    async copyUrl() {
      try {
        await navigator.clipboard.writeText(this.resultUrl);
        this.copyLinkIcon = 'mdi-check';
      } catch {
        this.copyLinkIcon = 'mdi-alert-circle';
      }
    }
  }
};
</script>
