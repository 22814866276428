var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.forms.length > 0 || _vm.loading)?_c('v-simple-table',{staticClass:"mb-8",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.$t('feedbacks.form')))]),_c('th',[_vm._v(_vm._s(_vm.$t('feedbacks.type_short')))]),_c('th',{staticStyle:{"width":"25%"}},[_vm._v(_vm._s(_vm.$t('forms.formActions')))])])]),(_vm.loading)?_c('thead',[_c('tr',{staticClass:"v-data-table__progress"},[_c('th',{staticClass:"column",attrs:{"colspan":"3"}},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)])]):_vm._e(),(_vm.loading)?_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t('dataLoading')))])])]):_vm._e(),(!_vm.loading)?_c('tbody',_vm._l((_vm.displayedItems),function(form){return _c('tr',{key:("form_" + (form.id))},[_c('td',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.localize(form.name), _vm.keyword))}}),_c('td',{domProps:{"innerHTML":_vm._s(
              _vm.$options.filters.highlight(
                _vm.$t(("feedbacks.processTypes." + (form.type))),
                _vm.keyword
              )
            )}}),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",staticStyle:{"width":"auto"},attrs:{"small":"","text":"","to":{ name: 'FormsEdit', params: { id: form.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"small":"","text":"","to":{ name: 'FormPreview', params: { id: form.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('forms.preview')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.copyForm(form.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('copy')))])]),_c('confirm',{on:{"confirmed":function($event){return _vm.deleteForm(form.id)}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-icon',[_vm._v("mdi-delete-forever")])]},proxy:true},{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.$t('forms.confirmDelete_header')))]},proxy:true},{key:"confirm-button",fn:function(){return [_vm._v(_vm._s(_vm.$t('delete')))]},proxy:true},{key:"tooltip",fn:function(){return [_vm._v(_vm._s(_vm.$t('delete')))]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('forms.confirmDelete_text'))+" ")]),_c('share-form',{attrs:{"formId":form.id}})],1)])}),0):_vm._e()]},proxy:true}],null,false,1909169941)}):_c('div',{staticClass:"pt-4"},[_c('v-alert',{attrs:{"type":"info","text":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t('forms.noFormsInfo'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }