<template>
  <div>
    <v-simple-table v-if="forms.length > 0 || loading" class="mb-8">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 40%">{{ $t('feedbacks.form') }}</th>
            <th>{{ $t('feedbacks.type_short') }}</th>
            <th style="width: 25%">{{ $t('forms.formActions') }}</th>
          </tr>
        </thead>
        <thead v-if="loading">
          <tr class="v-data-table__progress">
            <th class="column" colspan="3">
              <v-progress-linear indeterminate></v-progress-linear>
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr class="v-data-table__empty-wrapper">
            <td colspan="3">{{ $t('dataLoading') }}</td>
          </tr>
        </tbody>
        <tbody v-if="!loading">
          <tr v-for="form in displayedItems" :key="`form_${form.id}`">
            <td
              v-html="$options.filters.highlight(localize(form.name), keyword)"
            ></td>
            <td
              v-html="
                $options.filters.highlight(
                  $t(`feedbacks.processTypes.${form.type}`),
                  keyword
                )
              "
            ></td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    text
                    v-on="on"
                    v-bind="attrs"
                    class="px-0"
                    :to="{ name: 'FormsEdit', params: { id: form.id } }"
                    style="width: auto"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('edit') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    text
                    v-on="on"
                    v-bind="attrs"
                    class="px-0"
                    :to="{ name: 'FormPreview', params: { id: form.id } }"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('forms.preview') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    text
                    v-on="on"
                    v-bind="attrs"
                    class="px-0"
                    @click="copyForm(form.id)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('copy') }}</span>
              </v-tooltip>

              <confirm @confirmed="deleteForm(form.id)">
                <template v-slot:button>
                  <v-icon>mdi-delete-forever</v-icon>
                </template>

                <template v-slot:header>{{
                  $t('forms.confirmDelete_header')
                }}</template>
                <template v-slot:confirm-button>{{ $t('delete') }}</template>
                <template v-slot:tooltip>{{ $t('delete') }}</template>

                {{ $t('forms.confirmDelete_text') }}
              </confirm>

              <share-form :formId="form.id" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="pt-4">
      <v-alert type="info" text border="left">
        {{ $t('forms.noFormsInfo') }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import confirm from '@/components/dialogs/confirmation.vue';
import shareForm from '@/components/dialogs/share-form.vue';

export default {
  components: {
    confirm,
    shareForm
  },

  props: {
    forms: {
      type: Array,
      required: false,
      default: () => []
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    displayedItems() {
      if (this.forms.length === 0) {
        return [];
      }

      let items = this.forms;
      if (this.keyword.trim() !== '') {
        items = items.filter(
          (x) =>
            this.localize(x.name)
              .toLowerCase()
              .indexOf(this.keyword.toLowerCase()) >= 0 ||
            this.localize(x.typeLabel)
              .toLowerCase()
              .indexOf(this.keyword.toLowerCase()) >= 0
        );
      }

      const vm = this;
      return items.sort((a, b) => {
        if (
          vm.localize(a.name).toLowerCase() < vm.localize(b.name).toLowerCase()
        ) {
          return -1;
        } else if (
          vm.localize(a.name).toLowerCase() > vm.localize(b.name).toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },

  methods: {
    deleteForm(formId) {
      this.$emit('delete', formId);
    },

    async copyForm(formId) {
      this.$emit('copy', formId);
    },

    localize(item) {
      if (item.length === 0 || !Array.isArray(item)) {
        return '';
      }

      let langKey = 'de-DE';
      if (this.$i18n.locale === 'en') {
        langKey = 'en-US';
      }

      const translation = item.filter((x) => x.languageKey === langKey);
      if (translation.length > 0) {
        return translation[0].translation;
      }

      return item[0].translation;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table__progress th.column {
  padding: 0;
}
</style>
