<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title>
        <v-row class="align-center">
          <v-col cols="6">
            <h5>{{ $t('forms.forms') }}</h5>
          </v-col>
          <v-col cols="6" lg="4" offset-lg="2">
            <v-text-field
              v-model="keyword"
              solo
              flat
              hide-details
              :label="$t('searchKeywordPlaceholder')"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row v-show="forms.length >= 20">
          <v-col cols="12" class="text-right">
            <v-btn color="primary" :to="{ name: 'FormsCreate' }">
              <v-icon>mdi-plus</v-icon>
              {{ $t('forms.new') }}
            </v-btn>
          </v-col>
        </v-row>

        <forms-overview
          :forms="forms"
          :keyword="keyword"
          :loading="dataLoading"
          @delete="deleteItem"
          @copy="copyItem"
        ></forms-overview>

        <h4 class="caption">{{ $tc('forms.draft', 2) }}</h4>
        <forms-overview
          :forms="drafts"
          :keyword="keyword"
          :loading="dataLoading"
          @delete="deleteItem"
          @copy="copyItem"
        ></forms-overview>

        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" :to="{ name: 'FormsCreate' }">
              <v-icon>mdi-plus</v-icon>
              {{ $t('forms.new') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="successSnackbarDelete"
      color="success"
      :timeout="snackbarTimeout"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataDeleted') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-snackbar
      v-model="successSnackbarCopy"
      color="success"
      :timeout="snackbarTimeout"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataCopied') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import formsOverview from '@/components/forms/forms-table.vue';

export default {
  components: {
    formsOverview
  },

  data: () => ({
    keyword: '',
    dataLoading: true,
    items: [],
    correlationId: null,
    successSnackbarDelete: false,
    successSnackbarCopy: false,
    snackbarTimeout: 2000
  }),

  computed: {
    drafts() {
      return this.items.filter((x) => x.isDraft);
    },
    forms() {
      return this.items.filter((x) => !x.isDraft);
    }
  },

  async mounted() {
    this.items = await this.listForms();
    this.dataLoading = false;
    this.correlationId = uuidv4();

    this.$formsHub.enterCreateForm(this.correlationId);
    this.$formsHub.$on('form-deleted', this.onFormDeleted);
    this.$formsHub.$on('form-copied', this.onFormCopied);
  },

  beforeDestroy() {
    this.$formsHub.leaveCreateForm(this.correlationId);
    this.$formsHub.$off('form-deleted', this.onFormDeleted);
    this.$formsHub.$off('form-copied', this.onFormCopied);
  },

  methods: {
    ...mapActions({
      listForms: 'forms/listForms',
      copyForm: 'forms/copyForm',
      deleteForm: 'forms/deleteForm'
    }),

    copyItem(formId) {
      const original = this.items.find((x) => x.id === formId);
      const newTitles = JSON.parse(JSON.stringify(original.name));
      for (const language of newTitles) {
        language.translation = `${language.translation} ${this.$t(
          'copyOf',
          language.languageKey
        )}`;
      }
      const data = { id: formId, newTitle: newTitles };
      this.copyForm(data, this.correlationId);
    },

    deleteItem(formId) {
      this.deleteForm(formId, this.correlationId);
    },

    onFormDeleted(data) {
      this.items = this.items.filter((x) => x.id !== data.formId);
      this.successSnackbarDelete = true;
    },

    async onFormCopied(data) {
      const original = this.items.find((x) => x.id === data.formId);
      const copy = {
        id: data.copyId,
        name: data.newTitle,
        type: original.type,
        typeLabel: original.typeLabel,
        isDraft: original.isDraft
      };
      this.items.push(copy);
      this.successSnackbarCopy = true;
    }
  }
};
</script>
